import { Select } from "antd";
import { useLogs } from "../../providers/LogProvider";
import dayjs from "dayjs";

export const WorkerFilter = () => {
  const { workers, filters, setFilters } = useLogs();

  const handleWorkerChange = (worker: string[]) => {
    if (!filters) return;

    if (filters.relativeTime) {
      const relativeTime = filters.relativeTime.split("#");
      const now = dayjs();
      const dateFrom = now.subtract(
        parseInt(relativeTime[0]),
        relativeTime[1] as dayjs.ManipulateType,
      );
      setFilters({
        ...filters,
        dateFrom: dateFrom.valueOf().toString(),
        dateTo: now.valueOf().toString(),
        worker,
      });
    } else {
      setFilters({ ...filters, worker });
    }
  };

  return (
    <div style={{ width: "100%", margin: "0 10px" }}>
      <Select
        key={JSON.stringify(filters && filters.worker)}
        showSearch
        mode="multiple"
        placeholder="Select worker"
        style={{ width: "100%" }}
        // eslint-disable-next-line
        // @ts-ignore
        defaultValue={filters ? filters.worker : []}
        onChange={handleWorkerChange}
      >
        {workers.map((worker) => (
          <Select.Option key={worker.name} value={worker.name}>
            {worker.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
