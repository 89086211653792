import { JsonStringType } from "../providers/types";

export const findJsonStrings = (text: string): JsonStringType[] => {
  const jsonStrings = [];
  let currentString = "";
  let plainText = "";

  let expected = [];
  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    if (expected.length > 0 || ["{", "}", "[", "]"].includes(char)) {
      currentString += char;
    } else {
      plainText += char;
    }

    if (char === "{" || char === "[") {
      expected.push(char === "{" ? "}" : "]");
    } else if (char === "}" || char === "]") {
      if (expected[expected.length - 1] === char) {
        // found matching character, remove it from the expected stack
        expected.pop();

        if (expected.length === 0) {
          try {
            JSON.parse(currentString);
            jsonStrings.push({
              type: "text",
              content: plainText,
            });
            plainText = "";
            jsonStrings.push({ type: "json", content: currentString });
          } catch (error) {
            plainText += currentString;
            // Ignore non-parsable strings
          }
          currentString = "";
        }
      } else {
        // invalid string found, reset
        currentString = "";
        plainText += char;
        expected = [];
      }
    }
  }

  if (plainText.length > 0) {
    jsonStrings.push({
      type: "text",
      content: plainText,
    });
    plainText = "";
  }

  return jsonStrings as JsonStringType[];
};
